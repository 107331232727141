import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  catchError,
  map,
  Observable,
  switchMap,
  throwError as observableThrowError,
  throwError,
} from 'rxjs';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { environment } from 'src/environments/environment';


const OK_RESPONSES_CODE = [200,201,202,203,204]

@Injectable()
export class HandleErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private notification: NotificationsService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map(res => {
        //@ts-ignore
        if (res?.status && OK_RESPONSES_CODE.indexOf(res?.status) === -1 ) {
          //@ts-ignore
          throw new Error(res.body.message);
        }

        return res;
      }),
      catchError(error => {
        // Special types of errors are handled by auth-guard.
        if (error && error.status) {
          switch (error.status) {
            case 423:
              break;
            case 401:
              if (!environment.production) {
                this.router.navigate(['login']);
              } else {
                window.location.href = `/login`;
              }
              break;
            case 403:
              this.notification.info(error.message);
              break;
            default:
              this.notification.info(error.message);
              break;
          }

          return observableThrowError(error);
        }
        this.notification.info(error.message);

        return observableThrowError('An error has occurred, please try again.');
      })
    );
  }
}
