import { Component } from '@angular/core';
import { NotificationsService } from './shared/notifications/notifications.service';

@Component({
  selector: 'spai-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tenant-admin-ui';

  message: any;

  onCloseMessage(id: number):void {

  }

}
