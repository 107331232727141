import { Provider } from "@angular/core";
import provideMatAutocompleteDefaultOptions from "./autocomplete-default-options.provider";
import { provideMatFormFieldDefaultOptions } from "./formfield-default-options.provider";
import { provideMatMenuDefaultOptions } from "./menu-default-options.provider";
import { provideMatSelectDefaultOptions } from "./select-default-options.provider";


export function provideMatDefaultOptions(): Array<Provider> {
  return [
    provideMatAutocompleteDefaultOptions(),
    provideMatFormFieldDefaultOptions(),
    provideMatMenuDefaultOptions(),
    provideMatSelectDefaultOptions(),
  ]

}