import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './layout/layout.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { PopupComponent } from './shared/popup/popup.component';
import { NotificationsComponent } from './shared/notifications/notifications.component';
import { CodeEditorModule } from '@ngstack/code-editor';
import { provideMatDefaultOptions } from './shared/providers/material-providers/mat-providers';

@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent],
  imports: [BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MatToolbarModule,
    CoreModule,
    PopupComponent,
    NotificationsComponent,
    CodeEditorModule.forRoot({})],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideMatDefaultOptions(),
  ]

})
export class AppModule { }
