import { Provider } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';

export function provideMatFormFieldDefaultOptions(): Provider {
  return {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'outline',
      floatLabel: 'auto',
      subscriptSizing: 'fixed',
    } as MatFormFieldDefaultOptions,
  } as Provider;
}
