import { Provider } from '@angular/core';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

export function provideMatSelectDefaultOptions(): Provider {
  return {
    provide: MAT_SELECT_CONFIG,
    useValue: { 
      overlayPanelClass: 'spai-panel',
      hideSingleSelectionIndicator: true
    },
  } as Provider;
}
