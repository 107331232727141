import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {LocalStorage, MemoryStorage} from './storage/abstractStorage';
import {BaseMemoryStorage} from './storage/memory-starage';
import {BaseLocalStorage} from './storage/base-local.storage';
import {AuthHeaderInterceptor} from './interceptors/bearer-header.interceptor';
import {INIT_PROVIDERS} from './services/init.provider';
import {HandleErrorInterceptor} from './interceptors/error.interceptor';

export const INTERCEPTOR_PROVIDERS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHeaderInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HandleErrorInterceptor,
    multi: true,
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    ...INTERCEPTOR_PROVIDERS,
    ...INIT_PROVIDERS,
    {
      provide: LocalStorage,
      useClass: BaseLocalStorage,
    },
    {
      provide: MemoryStorage,
      useClass: BaseMemoryStorage,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {
}
